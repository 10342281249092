import React from 'react';
import {graphql, Link} from 'gatsby';
import ProductSidebar from '../components/ProductSidebar';
import BackgroundImage from 'gatsby-background-image-es5'
import {Helmet} from 'react-helmet'
import borderSVG from '../assets/sidebarBorder.svg'

export default (props) => {
  const imgData = props.data.graphCmsAsset.localFile.childImageSharp.fluid;

  return (
    <>
    <Helmet>
      <title>Our Products | Great Pine Resources</title>
      <meta name="description" content="Our products and services" />
    </Helmet>
    <div className='flex-row w-screen hidden lg:flex'>
      <ProductSidebar/>
      <div className='w-full '>
      <BackgroundImage
          Tag="section"
          fluid={imgData}
          backgroundColor={`#fff`}
        >
      <div className='product-container justify-center'>
        <img draggable="false" src={borderSVG} className={`sidebar-border`}/>
        
          <div className='text-2xl flex flex-row justify-center text-white font-semibold font-mont'>
            <p className='arrow-bounce-left pr-5'>◀</p>
            SELECT A PRODUCT TO LEARN MORE
          </div>
      </div>
      </BackgroundImage>
      </div>
    </div>
      <div className='w-screen h-full justify-around flex flex-col items-center lg:hidden p-5 pb-20'>
      <div className='flex flex-col items-center'>
      <p className='text-3xl py-5'>Our Products</p>
      <hr className='w-full py-3'/>
      {props.data.allGraphCmsProduct.nodes.map(
         product => 
         <Link 
          className='font-bold font-mont text-black text-opacity-40 my-2 text-center text-xl'
          title={product.productTitle} 
          to={`/products/${product.slug}`}>
            {product.productTitle}
         </Link>
       )}
      </div>
       <Link 
        to='/contact' 
        className='bg-yellow-base text-white text-center font-medium rounded-lg px-12 py-4 my-5 text-lg '>
        Contact Us
      </Link>
    </div>
    </>
  )
}

export const query = graphql`
query productLandingQuery {
  graphCmsAsset(fileName: {eq: "productLandingBG.jpg"}){
    id
    localFile{
      childImageSharp {
        fluid(maxWidth:1920,quality:90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  allGraphCmsProduct{
    nodes {
      slug
      productTitle
    }
  }
}
`